@use "../../../styles/sass/mixins.scss" as mixin;

.tabclix_input_field {
      margin-top: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #e2e2e2;
  border-radius: 0px;
  background-color: #fff;
  -webkit-transition: border-width 200ms ease, border-color 250ms ease;
  transition: border-width 200ms ease, border-color 250ms ease;
  font-size: 15px;
  font-weight: 600;
  @include mixin.LargeTablet {
    padding-top: 8px;
    padding-right: 8px;
    padding-left: 8px;
    background-color: #fff;
    -webkit-transition: border-width 250ms ease, border-color 250ms ease;
    transition: border-width 250ms ease, border-color 250ms ease;
  }
  @include mixin.LargeTablet {
    font-size: 16px;
  }
    @include mixin.smallPhone {
    font-size: 15px;
  }
}

.tabclix_input_field:focus {
  border-style: solid;
  border-width: 1px;
  border-color: #212529;
  @include mixin.LargeTablet {
    border-style: solid;
    border-width: 1px;
    border-color: #212529;
  }

}

.other_donation_input_block .tabclix_input_field{
  text-align: center;
  border: 0;
}

.other_donation_input_block .tabclix_input_field:focus{
  border: 0;
}

.error_form_message {
  color: red;
}
.success_form_message {
  color: red;
}



.button {

  @if variable-exists(var(--form-button-color)){
    background-color: var(--form-button-color) !important;
  } @else {
    background-color: #212529;
  }

  @if variable-exists(var(--form-button-color)){
    color: var(--form-button-text-color) !important;
  } @else {
    color: white;
  }
  padding: 12px 24px;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  &:disabled {
    opacity: 0.7;
    color: var(--form-button-text-color) !important;
    background-color: var(--form-button-color) !important;
  }
  @include mixin.LargeTablet {
    font-size: 15px;
  }
}

.tabclix__w_button {
  display: inline-block;
  padding: 9px 15px;
  -webkit-appearance: button;
  color: white;
  border: 0;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0;
}

.button_block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0;
  margin-left: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.button:disabled{
  color: rgba(33, 37, 41, 0.5215686275);
}

.donation_block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 12px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 25%;
  -ms-flex: 0 25%;
  flex: 0 25%;
  border: 2px solid #fff;
  background-color: #fff;
}

.form_error_submission {
  padding: 5px;
  margin: 15px 0;
  border-style: solid;
  border-color: #f0065486;
  border-width: 2px;
  border-radius: 5px;
  // background-color: #ffffff;
  color: #f00654f5;
}
.form_success_submission {
  padding: 5px;
  margin: 15px 0;
  border-style: solid;
  border-color: #158a1ff5;
  border-width: 2px;
  border-radius: 5px;
  // background-color: #ffffff;
  color: #158a1ff5;
}

.hidden_input {
 color : red !important;
}

.tabclix__w_input {
  margin-top: 5px;
  font-family: 'Source Sans Pro', sans-serif !important;
  background-color: #fff;
  border: 1px solid #ccc;
  color: #333;
  display: block;

  height: 38px;
  line-height: 1.428571429;
  margin-bottom: 10px;
  padding: 8px 12px;
  vertical-align: middle;
  width: 100%;

  border: 1px solid #e2e2e2;
  border-radius: 0;
  font-size: 15px;
  font-weight: 600;
  transition: border-width .2s ease,border-color .25s ease;
  &:disabled {
    background-color: #eee;
  }
}

.tabclix__w_input:-moz-placeholder,
.w-select:-moz-placeholder {
  color: #999;
}
.tabclix__w_input::-moz-placeholder,
.w-select::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.tabclix__w_input:-ms-input-placeholder,
.w-select:-ms-input-placeholder {
  color: #999;
}
.tabclix__w_input::-webkit-input-placeholder,
.w-select::-webkit-input-placeholder {
  color: #999;
}
.tabclix__w_input:focus,
.w-select:focus {
  outline: 0;
}
.tabclix__w_input[disabled],
.w-select[disabled],
.tabclix__w_input[readonly],
.w-select[readonly],
fieldset[disabled] .tabclix__w_input,
fieldset[disabled] .w-select {
  cursor: not-allowed;
}
textarea.tabclix__w_input,
textarea.w-select {
  height: auto;
}

