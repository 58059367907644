.image {
  width: 100%;
  -o-object-fit: fill;
  object-fit: fill;
  -webkit-animation: in 3s 0s;
  animation: in 3s 0s;
  margin-top: 0px;
  margin-bottom: 20px;
}

@-webkit-keyframes in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}