.tabclix__label_radio {
    display: flex;
    gap: 6px;
    margin-top: 7px;
}
.tabclix__radio {
  box-sizing: border-box;
  padding: 0;
  accent-color: #212529;
}
