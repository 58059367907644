@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

.react-player > div {
  height: 300px !important; 
}
.player-container{
margin-bottom: 20px;
}

#tabclix___div_root a {
  /* color: #495057; */
}

.react-player > div > iframe {
  height: 100% !important;

}
.vp-center {
  /* height: 1000px; */
}
.react-player {
  width: 100% !important;
  height: 100% !important;
}

#tabclix___div_root a {
  color: #495057;
}

#tabclix___div_root {
    border: none;
    position: fixed;
    width: 100%;
    z-index: 999999;
    top: auto;
    display: -webkit-flex;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
    flex-direction: column;
    top: 0;
    bottom: auto;
    /* height: 100vh; */
    left: 0;
    overflow-y: scroll;
    /* position: sticky; */
    right: 0;
    align-items: stretch;
    top: 0;
    z-index: 999999;
  font-family: 'Source Sans Pro', sans-serif;
}

/* .tabclix__label {
  display: block;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: 400;
  all: initial;
  all: unset;
} */

#tabclix___div_root .GooglePayButton-logo {
  padding: 34.5vh 7.375vw !important;
}

#tabclix___div_root .ApplePayButton-logo {
  padding: 34.5vh 7.375vw !important;
}

#tabclix___div_root::-webkit-scrollbar {
  width: 2px;
}

/* Track */
#tabclix___div_roott::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
#tabclix___div_root::-webkit-scrollbar-thumb {
  background: #212529;
  border-radius: 10px;
}

/* Handle on hover */
#tabclix___div_root::-webkit-scrollbar-thumb:hover {
  background: #3f4348;
}
#tabclix___div_root  .close-button,
#tabclix___div_root .back-button {
    font-family: Materialicons, sans-serif;
    color: #212529;
    font-size: 32px;
    cursor: pointer;
  }

@media screen and (max-width: 991px) {
#tabclix___div_root   .close-button,
#tabclix___div_root .back-button {
    font-size: 32px;
  }
}
@media screen and (max-width: 479px) {
#tabclix___div_root   .close-button,
#tabclix___div_root .back-button {
    font-size: 25px;
  }
}
#tabclix___div_root .tbx-card-icon {
  color: var(--card-title-color) !important;
  /* color: var(--card-right-border-icon-color) !important; */
  color: #000000;
  cursor: pointer;
}

@font-face {
  font-family: "tabclix";
  src: url("https://tabclixdata.s3.ca-central-1.amazonaws.com/tabclixunlockbike.eot");
  src: url("https://tabclixdata.s3.ca-central-1.amazonaws.com/tabclixunlockbike.eot") format("embedded-opentype"), url("https://tabclixdata.s3.ca-central-1.amazonaws.com/tabclixunlockbike.ttf") format("truetype"), url("https://tabclixdata.s3.ca-central-1.amazonaws.com/tabclixunlockbike.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=tabclixunlock-], [class*=" tabclixunlock-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "tabclix" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.tbx-profile-plus2-outline:before {
  content: "\e964";
}
.tbx-profile-plus2:before {
  content: "\e965";
}
.active.tbx-profile-plus2-outline:before {
  content: "\e965";
}

.tabclix-unlockbike-outline:before {
  content: "\e969";
}

.tabclix-unlockbike:before {
  content: "\e970";
}

.active.tabclix-unlockbike-outline:before {
  content: "\e970";
}

@font-face {
  font-family: "tabclix";
  src: url("https://tabclixdata.s3.ca-central-1.amazonaws.com/fonts/tabclix.eot");
  src: url("https://tabclixdata.s3.ca-central-1.amazonaws.com/fonts/tabclix.eot") format("embedded-opentype"), url("https://tabclixdata.s3.ca-central-1.amazonaws.com/fonts/tabclix.ttf") format("truetype"), url("https://tabclixdata.s3.ca-central-1.amazonaws.com/fonts/tabclix.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="tbx-"], [class*=" tbx-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "tabclix" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 30px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tbx-glass-wine:before {
  content: "\e970";
}
.tbx-glass-wine-outline:before {
  content: "\e975";
}
.active.tbx-glass-wine-outline:before  {
  content: "\e970";
}


.tbx-beer:before {
  content: "\e971";
}
.tbx-beer-outline:before {
  content: "\e976";
}
.active.tbx-beer-outline:before {
  content: "\e971";
}


.tbx-bottle-wine:before {
  content: "\e972";
}
.tbx-bottle-wine-outline:before {
  content: "\e977";
}
.active.tbx-bottle-wine-outline:before {
  content: "\e972";
}


.tbx-cocktails:before {
  content: "\e973";
}
.tbx-cocktails-outline:before {
  content: "\e978";
}
.active.tbx-cocktails-outline:before {
  content: "\e973";
}


.tbx-hard-liquor:before {
  content: "\e974";
}
.tbx-hard-liquor-outline:before {
  content: "\e979";
}
.active.tbx-hard-liquor-outline:before {
  content: "\e974";
}

.tbx-x-tabclix:before {
  content: "\e96d";
}
.tbx-calendar:before {
  content: "\e96e";
}
.tbx-tabclix-logo:before {
  content: "\e96f";
}


.tbx-facebook:before {
  content: "\e966";
}
.tbx-fb-outline:before {
  content: "\e969";
}

.tbx-youtube:before {
  content: "\e967";
}
.tbx-youtube-outline:before {
  content: "\e96c";
}

.tbx-dollar:before {
  content: "\e968";
}

.tbx-thermometer-outline:before {
  content: "\e96a";
}
.tbx-vimeo:before {
  content: "\e96b";
}



.tbx-upload-folder-outline:before {
  content: "\e95c";
}
.tbx-button-outline:before {
  content: "\e95d";
}
.tbx-checkbox-outline:before {
  content: "\e95e";
}
.tbx-dropdown-outline:before {
  content: "\e95f";
}
.tbx-radio-outline:before {
  content: "\e960";
}
.tbx-subscription-outline-2:before {
  content: "\e961";
}
.tbx-input-label-outline:before {
  content: "\e962";
}
.tbx-input-text-outline:before {
  content: "\e963";
}
.tbx-profile-plus:before {
  content: "\e95a";
}
.tbx-profile-plus-outline:before {
  content: "\e95b";
}
.active.tbx-profile-plus-outline:before {
  content: "\e95a";
}


.tbx-tiktok:before, .tabclix-tiktok:before {
  content: "\e959";
}


.tbx-analytics:before, .tabclix-analytics:before {
  content: "\e900";
}
.tbx-analytics-outline:before, .tabclix-analytics-outline:before {
  content: "\e901";
}
.active.tbx-analytics-outline:before, .active.tabclix-analytics-outline:before{
  content: "\e900";
}


.tbx-appearance:before, .tabclix-appearance:before {
  content: "\e902";
}
.tbx-appearance-outline:before, .tabclix-appearance-outline {
  content: "\e903";
}
.active.tbx-appearance-outline:before, .active.tabclix-appearance-outline:before {
  content: "\e902";
}


.tbx-back1:before, .tabclix-back1:before {
  content: "\e904";
}
.tbx-back1-outline:before, .tabclix-back1-outline:before {
  content: "\e905";
}
.active.tbx-back1-outline:before, .active.tabclix-back1-outline:before {
  content: "\e904";
}

.tbx-back2-outline:before, .tabclix-back2-outline:before {
  content: "\e906";
}
.tbx-bike-outline:before {
  content: "\e907";
}


.tbx-billing:before, .tabclix-billing:before {
  content: "\e908";
}
.tbx-billing-outline:before, .tabclix-billing-outline:before {
  content: "\e909";
}
.active.tbx-billing-outline:before, .active.tabclix-billing-outline:before {
  content: "\e908";
}


.tbx-buypass:before, .tabclix-buypass:before {
  content: "\e90a";
}
.tbx-buypass-outline:before, .tabclix-buypass-outline:before {
  content: "\e90b";
}
.active.tbx-buypass-outline:before, .active.tabclix-buypass-outline:before {
  content: "\e90a";
}

.tbx-calendly:before, .tabclix-calendly:before {
  content: "\e90c";
}


.tbx-call:before, .tabclix-call:before {
  content: "\e90d";
}
.tbx-call-outline:before, .tabclix-call-outline:before {
  content: "\e910";
}
.active.tbx-call-outline:before, .active.tabclix-call-outline:before {
  content: "\e90d";
}


.tbx-call2:before, .tabclix-call2:before {
  content: "\e90e";
}
.tbx-call2-outline:before, .tabclix-call2-outline:before {
  content: "\e90f";
}
.active.tbx-call2-outline:before, .active.tabclix-call2:before {
  content: "\e90e";
}


.tbx-chat:before, .tabclix-chat:before {
  content: "\e911";
}
.tbx-chat-outline:before, .tabclix-chat-outline:before  {
  content: "\e912";
}
.active.tbx-chat-outline:before , .active.tabclix-chat-outline:before {
  content: "\e911";
}


.tbx-close-outline:before , .tabclix-close-outline:before{
  content: "\e913";
}


.tbx-donate:before , .tabclix-donate:before {
  content: "\e914";
}
.tbx-donate-outline:before, .tabclix-donate-outline:before {
  content: "\e915";
}
.active.tbx-donate-outline:before , .active.tabclix-donate-outline:before {
  content: "\e914";
}


.tbx-edit:before, .tabclix-edit:before {
  content: "\e916";
}
.tbx-edit-outline:before ,.tabclix-edit-outline:before  {
  content: "\e917";
}
.active.tbx-edit-outline:before, .active.tabclix-edit-outline:before {
  content: "\e916";
}



.tbx-fb-messenger:before , .tabclix-fb-messenger:before{
  content: "\e918";
  color: #448AFF;
}
.tbx-fb-messenger-outline:before , .tabclix-fb-messenger-outline:before {
  content: "\e919";
  color: #448AFF;
}
.active.tbx-fb-messenger-outline:before, .active.tabclix-fb-messenger-outline:before {
  content: "\e918";
}


.tbx-fingerprint-outline:before, .tabclix-fingerprint-outline:before {
  content: "\e91a";
}


.tbx-form:before , .tabclix-form:before {
  content: "\e91b";
}
.tbx-form-outline:before, .tabclix-form-outline:before {
  content: "\e91c";
}
.active.tbx-form-outline:before, .active.tabclix-form-outline:before {
  content: "\e91b";
}


.tbx-gps:before , .tabclix-gps:before {
  content: "\e91d";
}
.tbx-gps-outline:before, .tabclix-gps-outline:before {
  content: "\e91e";
}
.active.tbx-gps-outline:before, .active.tabclix-gps-outline:before  {
  content: "\e91d";
}

.tbx-home:before, .tabclix-home:before {
  content: "\e91f";
}
.tbx-home-outline:before, .tabclix-home-outline:before {
  content: "\e920";
}
.active.tbx-home-outline:before , .active.tabclix-home-outline:before{
  content: "\e91f";
}


.tbx-info:before, .tabclix-info:before {
  content: "\e921";
}
.tbx-info-outline:before, .tabclix-info-outline:before {
  content: "\e922";
}
.active.tbx-info-outline:before, .active.tabclix-info-outline:before  {
  content: "\e921";
}


.tbx-instagram:before , .tabclix-instagram:before{
  content: "\e923";
}
.tbx-instagram-outline:before, .tabclix-instagram-outline:before {
  content: "\e924";
}
.active.tbx-instagram-outline:before, .active.tabclix-instagram-outline:before {
  content: "\e923";
}


.tbx-keycard-outline:before , .tabclix-keycard-outline:before {
  content: "\e925";
}
.tbx-label-outline:before, .tabclix-label-outline:before {
  content: "\e926";
}


.tbx-linkedin:before, .tabclix-linkedin:before, .tab-linkedin {
  content: "\e927";
}
.tbx-linkedin-outline:before, .tabclix-linkedin-outline:before {
  content: "\e928";
}
.active.tbx-linkedin-outline:before, .active.tabclix-linkedin-outline:before {
  content: "\e927";
}


.tbx-lock1:before, .tabclix-lock1:before {
  content: "\e929";
}
.tbx-lock1-outline:before, .tabclix-lock1-outline:before {
  content: "\e92a";
}
.active.tbx-lock1-outline:before, .active.tabclix-lock1-outline:before {
  content: "\e929";
}


.tbx-lock2:before, .tabclix-lock2:before {
  content: "\e92b";
}
.tbx-lock2-outline:before, .tabclix-lock2-outline:before {
  content: "\e92c";
}
.active.tbx-lock2-outline:before, .active.tabclix-lock2-outline:before {
  content: "\e92b";
}


.tbx-logout:before, .tabclix-logout:before {
  content: "\e92d";
}
.tbx-logout-outline:before, .tabclix-logout-outline:before  {
  content: "\e92e";
}
.active.tbx-logout-outline:before, .active.tabclix-logout-outline:before  {
  content: "\e92d";
}


.tbx-map:before, .tabclix-map:before {
  content: "\e92f";
}
.tbx-map-outline:before, .tabclix-map-outline:before {
  content: "\e930";
}
.active.tbx-map-outline:before, .active.tabclix-map-outline:before  {
  content: "\e92f";
}


.tbx-menu1:before, .tabclix-menu1:before {
  content: "\e931";
}
.tbx-menu1-outline:before , .tabclix-menu1-outline:before {
  content: "\e932";
}
.active.tbx-menu1-outline:before, .active.tabclix-menu1-outline:before {
  content: "\e931";
}


.tbx-menu2:before, .tabclix-menu2:before {
  content: "\e933";
}
.tbx-menu2-outline:before, .tabclix-menu2-outline:before {
  content: "\e934";
}
.active.tbx-menu2-outline:before, .active.tabclix-menu2-outline:before  {
  content: "\e933";
}


.tbx-menu3:before , .tabclix-menu3:before{
  content: "\e935";
}
.tbx-menu3-outline:before, .tabclix-menu3-outline:before {
  content: "\e936";
}
.active.tbx-menu3-outline:before, .active.tabclix-menu3-outline:before  {
  content: "\e935";
}


.tbx-menu4:before, .tabclix-menu4:before {
  content: "\e937";
}
.tbx-menu4-outline:before, .tabclix-menu4-outline:before {
  content: "\e938";
}
.active.tbx-menu4-outline:before, .tabclix.tbx-menu4-outline:before {
  content: "\e937";
}


.tbx-objectcursor:before, .tabclix-objectcursor:before  {
  content: "\e939";
}
.tbx-objectcursor-outline:before, .tabclix-objectcursor-outline:before  {
  content: "\e93a";
}
.active.tbx-objectcursor-outline:before, .active.tabclix-objectcursor-outline:before {
  content: "\e939";
}


.tbx-overview-outline:before, .tabclix-overview-outline:before {
  content: "\e93b";
}

.tbx-paymentintegration:before, .tabclix-paymentintegration:before {
  content: "\e93c";
}
.tbx-paymentintegration-outline:before , .tabclix-paymentintegration-outline:before {
  content: "\e93d";
}
.active.tbx-paymentintegration-outline:before, .active.tabclix-paymentintegration-outline:before {
  content: "\e93c";
}

.tbx-payment-outline:before, .tabclix-payment-outline:before {
  content: "\e93e";
}


.tbx-preview:before, .tabclix-preview:before  {
  content: "\e93f";
}
.tbx-preview-outline:before, .tabclix-preview-outline:before {
  content: "\e940";
}
.active.tbx-preview-outline:before, .active.tabclix-preview-outline:before {
  content: "\e93f";
}


.tbx-profile-outline:before, .tabclix-profile-outline:before {
  content: "\e941";
}


.tbx-profile-settings:before, .tabclix-profile-settings:before {
  content: "\e942";
}
.tbx-profile-settings-outline:before, .tabclix-profile-settings-outline:before {
  content: "\e943";
}
.active.tbx-profile-settings-outline:before, .tabclix.tbx-profile-settings-outline:before  {
  content: "\e942";
}


.tbx-routes-outline:before, .tabclix-routes-outline:before {
  content: "\e944";
}


.tbx-settings:before, .tabclix-settings:before {
  content: "\e945";
}
.tbx-settings-outline:before, .tabclix-settings-outline:before {
  content: "\e946";
}
.active.tbx-settings-outline:before, .active.tabclix-settings-outline:before {
  content: "\e945";
}


.tbx-singleuser:before, .tabclix-singleuser:before {
  content: "\e947";
}
.tbx-singleuser-outline:before, .tabclix-singleuser-outline:before  {
  content: "\e948";
}
.active.tbx-singleuser-outline:before, .active.tabclix-singleuser-outline:before {
  content: "\e947";
}

.tbx-subscription-outline:before, .tabclix-subscription-outline:before {
  content: "\e949";
}
.tbx-transactions-outline:before, .tabclix-transactions-outline:before  {
  content: "\e94a";
}


.tbx-twitter:before, .tabclix-twitter:before {
  content: "\e94b";
  color: #448AFF;
}
.tbx-twitter-outline:before, .tabclix-twitter-outline:before {
  content: "\e94c";
  color: #448AFF;
}
.active.tbx-twitter-outline:before, .active.tabclix-twitter-outline:before {
  content: "\e94b";
  color: #448AFF;
}


.tbx-unlock:before, .tabclix-unlock:before {
  content: "\e94d";
}
.tbx-unlock-outline:before, .tabclix-unlock-outline:before {
  content: "\e951";
}
.active.tbx-unlock-outline:before, .active.tabclix-unlock-outline:before{
  content: "\e94d";
}


.tbx-unlock2:before, .tabclix-unlock2:before {
  content: "\e94e";
}
.tbx-unlock2-outline:before, .tabclix-unlock2-outline:before {
  content: "\e94f";
}
.active.tbx-unlock2-outline:before, .active.tabclix-unlock2-outline:before {
  content: "\e94e";
}


.tbx-unlockbike-outline:before, .tabclix-unlockbike-outline:before {
  content: "\e950";
}
.tbx-Visible:before, .tabclix-Visible:before {
  content: "\e952";
}


.tbx-wallet:before, .tabclix-wallet:before {
  content: "\e953";
}
.tbx-wallet-outline:before, .tabclix-wallet-outline:before {
  content: "\e954";
}
.active.tbx-wallet-outline:before, .active.tabclix-wallet-outline:before  {
  content: "\e953";
}


.tbx-web:before, .tabclix-web:before {
  content: "\e955";
}


.tbx-whatsapp:before, .tabclix-whatsapp:before {
  content: "\e956";
  color: #2AD348;
}
.tbx-whatsapp-outline:before, .tabclix-whatsapp-outline:before {
  content: "\e957";
  color: #2AD348;
}
.active.tbx-whatsapp-outline:before, .active.tabclix-whatsapp-outline:before {
  content: "\e956";
  color: #2AD348;
}


.tbx-zendesk:before, .tabclix-zendesk:before {
  content: "\e958";
  color: #050505;
}



@font-face {
  font-family: "Materialicons";
  src: url("https://tabclixdata.s3.ca-central-1.amazonaws.com/MaterialIcons-Regular.woff2") format("woff2"), url("https://tabclixdata.s3.ca-central-1.amazonaws.com/MaterialIcons-Regular.woff") format("woff"), url("https://tabclixdata.s3.ca-central-1.amazonaws.com/MaterialIcons-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Materialiconssharp";
  src: url("https://tabclixdata.s3.ca-central-1.amazonaws.com/MaterialIconsSharp-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Materialiconstwotone";
  src: url("https://tabclixdata.s3.ca-central-1.amazonaws.com/MaterialIconsTwoTone-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Materialiconsoutlined";
  src: url("https://tabclixdata.s3.ca-central-1.amazonaws.com/MaterialIconsOutlined-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Materialiconsround";
  src: url("https://tabclixdata.s3.ca-central-1.amazonaws.com/MaterialIconsRound-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
