@use "../../styles/sass/mixins.scss" as mixin;

.cards {
    cursor:pointer;
}

.icon_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 36px;
  height: 36px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.card_content_block {
    background-color: var(--card-background-color);
    // border: solid 2px var(--card-border-color);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 20px;
    padding: 16px;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    -webkit-box-align: flex-start;
    -webkit-align-items: center;
    -ms-flex-align: flex-start;
  align-items: center;
}

.title_icon_container {
    display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  height: 35px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.card_right_border_image
{
  display: flex;
  align-items: center;
  justify-content: center;
  & img{
    width: 9 0px;
  }

}

.card_texts {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.payment {
    width: auto;
    height: auto;
    margin-right: 14px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.payment_img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
}

.tabclix_text_large {
  margin-bottom: 5px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #212529;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  @include mixin.smallPhone {
    font-size: 14px;
  }
}

.icon_animation {
    animation: logoRotation 0.6s both;
    -webkit-animation: logoRotation 0.6s both;
}

.card_right_border_icon {
  @if variable-exists(var(--card-right-border-icon-color)){
  } @else {
    color: #212529;
  }
  color: var(--card-right-border-icon-color) !important;
}

@keyframes logoRotation {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.card_animation {
  animation:slider 0.6s ease-out  both ;
  -webkit-animation: slider 0.6s ease-out both;
}
@keyframes slider {
    from {
        transform:  translate3d(0px, 70px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
    }
    to {
        transform:  translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
    }
}

.card_text_animation {
    animation-name: card-text-animation;
    -webkit-animation-name: card-text-animation;
    animation-duration: 0.6s;
    -webkit-animation-duration: 0.6s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    animation-timing-function: ease;
    -webkit-animation-timing-function: ease;
}

@keyframes card-text-animation {
    from {
        opacity: 0.3;
        transform: translate(0, 15px);
    }
    
    to {
        opacity: 1;
        transform: translate(0, 0);
    }
}
