@use '../../styles/sass/mixins.scss' as mixin;

.header_container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 25px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.header_icon {
  align-items: center;
  padding-right: 10px;
  // width: 70px;
}

.header_title {
  font-family: var(--header-title-font) !important;
  // color: #212529;
  
  margin-bottom: 0;

  margin-top: 0px;
  text-transform: initial;
  padding-bottom: 0px;
  font-size: 32px;
  @include mixin.mediumPhone {
    font-size: 24px;
  }
  @include mixin.smallPhone {
    font-size: 22px;
  }
}

.header_img{
  width: 60px;
}

.text_span_4 {
  font-weight: 700;
    @include mixin.LargeTablet {
    font-weight: 700;
  }
}

.header_button_container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.x_button_block {
  margin-left: 5px;
}