.tabclix_donation_button.button {
  &:disabled {
    @if variable-exists(var(--form-button-text-color)){
      color: var(--form-button-text-color) !important;
    } @else {
      color: white;
    }
     opacity: 0.7;
     
     background-color: var(--form-button-color) !important;
   }
}

.tabclix_donation_button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 18px;
  padding: 13px;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  color: #000;
  &:disabled {
    opacity: 0.6;
  }
}

