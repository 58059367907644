.tabclix_checkbox_field {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.checkbox {
  display: inline-block; /** set to `inline-block` as `inline elements ignore `height` and `width`*/
  height: 18px;
  width: 18px;
  background: #fff;
  border: 2px none;
  margin-right: 4px;
}

.checkbox__active {
  border-color: #212529;
  background: #212529;
}


.tabclix__checkbox {
  box-sizing: border-box;
  padding: 0;
  background-color: initial;
  cursor: default;
  appearance: auto;
  box-sizing: border-box;
  margin: 3px 3px 3px 4px;
  padding: initial;
  border: initial;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}