@use "../styles/sass/mixins.scss" as mixin;

.w_inline_block {
  max-width: 100%;
  display: inline-block;
}

.mobile_bar { 
  height: 60px;
  background-image: transparent;
  @include mixin.LargeTablet {
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    z-index: 9999;
  }
  @include mixin.mediumPhone {
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    z-index: 9999;
    display: block;
    height: 60px;
    background-color: rgb(255, 255, 255);
    padding-top: 14px;
    padding-right: 10px;
    padding-left: 10px;
    // background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#ffffff));
    // background-image: linear-gradient(180deg, #ffffff, #ffffff);
  }
}

.mobile_bar_menu {
  @if variable-exists(--mobile-bar-color){
  } @else {
  }
  background: var(--mobile-bar-color) !important;
  // background: #212529;
  position: fixed;
  left: 0;
  top: auto;
  right: 0;
  bottom: 0;
  z-index: 9999;
  max-height: 64px !important ;
  min-height: 61px;
  // padding: 16px 1vw;
  // background-color: transparent;
  // background-image: linear-gradient(180deg, #212529, #343a40);
  padding: 0px 1vw;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  @include mixin.LargeTablet {
    padding: 0px 1vw;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  @include mixin.smallTablet {
    padding: 0px 1vw;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  @include mixin.mediumPhone {
    // padding: 7px 20px; 
    padding: 0px 1vw;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}