.message-wrapper{
    position: fixed;
    top: 10px;
    right: 10px;
    width: 400px;
  }
  
  .message-item{
    padding: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
    animation: SlideLeft 0.5s ;
    animation-fill-mode: forward;
    width: 400px;
    color: rgb(56, 50, 50);
  }
      /* enter */
  /* @keyframes SlideLeft{
    0% {
      margin-left: 120%;
    }
  
    100%{
      margin-left: 0;
    }
  } */
  
  /* .message-item.exit {
    animation: SlideRight 0.5s ;
    animation-fill-mode: forward;
  }
  
    /* exit */
  /* @keyframes SlideRight{
    0% {
      margin-left: 0;
    }
  
    100%{
      margin-left: 120%;
    }
  } */ 
  
  
  .message-item p, h5 {
    margin: 0;
    padding: 5px 3px; 
    margin-left: 5px;
  }
  
  .message-item .bar {
    height: 5px;
    /* margin: 5px; */
    /* background-color: #643045; */
  }
  
  
  .message-item.success {
    background-color: #e3ffeb;
  }
  .message-item.success .bar{
    background-color: #57f080;
  }
  
  
  .message-item.error {
    background-color: rgb(230, 214, 214);
  }
  .message-item.error .bar {
    background-color: rgb(255, 0, 0);
  }
  
  
  .message-item.info {
    background-color: #cfe4ec;
  }
  .message-item.info .bar {
    background-color: #2b8bb1;
  }
  
  
  .message-item.warning {
    background-color: #f0ddc6;
  }
  .message-item.warning .bar {
    background-color: #ff9100;
  }
  
  
  .inner-border{
    padding: 5px;
    border: solid 0.5px rgb(150, 150, 150);
    margin: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    
  }
  
  .align-button{
    display: flex;
  }
  .close-button{
    background:transparent;
    border: none;
    margin-left: auto;
    margin-right: 5px;
  }
  
  .close-button:hover{
    color: #706d6d;
  
  }
  
  
  .title-message{
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  
  .message-button{
    margin-left: 15px;
    border-radius: 6px;
    background-color: rgb(46, 144, 173);
    color: white;
    border: solid 1px;
  }
  
  .message-button:hover{
    margin-left: 15px;
    border-radius: 6px;
    background-color: rgb(47, 166, 202);
    color: white;
    border: solid 1px white;
    
  }