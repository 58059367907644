@use "../../styles/sass/mixins.scss" as mixin;

.card_description {
  color: var(--card-title-color) !important;
  
  @if variable-exists(var(--card-title-color)){
  } @else {
    // color: #212529;
  }
  font-weight: 400;
  white-space: pre-line;
}

.bold_text {
  font-weight: bold;
}

.skip_line {
  white-space: pre-line;
}

.tbx_h1 {
  font-weight: 600;
  // font-size: 36px !important;
  line-height: 38px;
  font-family: "Source Sans Pro", sans-serif;
  // margin: 50px 0;
  @include mixin.mediumPhone {
    // font-size: 30px !important;
  }
  @include mixin.smallPhone {
    // font-size: 26px !important;
  }
}
.tbx_h2 {
  font-weight: 400;
  font-size: 22px;
  line-height: 35px;
  font-family: "Source Sans Pro", sans-serif;
  margin: 20px 0;
}

.globalH2 {
  font-weight: normal !important;
  line-height: 36px;
  margin-top: 20px;
}

.tbx_p1 {
  font-family: "Source Sans Pro", sans-serif;
  letter-spacing: 0.1px;
  font-weight: 400;
  color: #333;
  line-height: 45px;
}

.tabclix__a {
  text-decoration: underline;
  cursor:pointer;
  color:var(--tabclix-primary-color) !important;
  display: block !important;
}

.tabclix__text_regular {
  color: #212529;
}

.tabclix__text_large {
  margin-bottom: 5px;
  font-family: 'Source Sans Pro', sans-serif;
  color: #212529;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  @include mixin.smallPhone {
    font-size: 14px;
  }
}

.tabclix__text_small {
  @include mixin.LargeTablet{
  margin-bottom: 5px;
  font-family: "Source Sans Pro", sans-serif;
  color: #212529;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-weight: 600;
  }
  @include mixin.smallTablet {
    font-weight: 600;
  }
  @include mixin.mediumPhone {
    margin-bottom: 0px;
    font-size: 10px;
    font-weight: 400;
    text-align: left;
    letter-spacing: 0px;
  }
}

.medium_text_size {
  font-size: 16px;
  line-height: 22px;
}

.small_text_size {
  font-size: 14px;
}

.large_text_size {
  font-size: 18px;
}

.card_title {
  color: var(--card-title-color) !important;
  @if variable-exists(var(--card-title-font)){
    font-family: var(--card-title-font) !important;
  } @else {
    font-family: 'Source Sans Pro', sans-serif ;
  }
  // color: var(--card-title-color) !important;
  // color: #212529;
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  letter-spacing: 0.5px; 
  @include mixin.smallPhone {
    font-size: 14px;
  }
}

.tabclix__label {
    display: block;
    margin-bottom: 14px;
    font-size: 15px;
    font-weight: 400;
    all: initial;
    all: unset;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    font-family: 'Source Sans Pro', sans-serif !important;
    line-height: 20px;
}



