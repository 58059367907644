@use "../../styles/sass/mixins.scss" as mixin;


.mobile_bar_icon_description{
  white-space: nowrap;
  font-size: var(--mobile-bar-icon-description-font-size);
  color: white !important;
}

.tabclix_mobile_bar_icon_container {
  display: grid;
  justify-items: center;
  margin-top: 11px;
}

.tabclix_icon_mobile_bar {
  @if variable-exists(var(--navigation-icon-color)){
  } @else {
    color: white;
  }
  color: var(--navigation-icon-color);
  cursor: pointer;
}

.tabclix_icon_container { 
  width: 100px;
  @include mixin.smallTablet {
    width: 120px;
  }
  @include mixin.LargeTablet {
    width: 200px;
  }

}