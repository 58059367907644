#tabclix___div_root {
  all: initial ;
}
#tabclix___div_root * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

}
#tabclix___div_root {
  height: 100vh;
}
body #tabclix___div_root {
  margin: 0;
  /* min-height: 10%; */
  background-color: #fff;
  font-family: 'Source Sans Pro', sans-serif !important;
  /* font-size: 14px; */
  line-height: 20px;
  color: #333;
  
}



/* input.tabclix__w_button {
  -webkit-appearance: button;
} */
/* input[type='search'] {
  height: 40px;
  border: 0px;
} */

#tabclix___div_root h1,
#tabclix___div_root h3,
#tabclix___div_root h4,
#tabclix___div_root h5,
#tabclix___div_root h6 {
  font-weight: bold;
  margin-bottom: 10px;
}
/* h1 {
  font-size: 38px;
  line-height: 44px;
  margin-top: 20px;
} */
/* h2 {
  font-weight: normal !important;
  line-height: 36px;
  margin-top: 20px;
} */

#tabclix___div_root p {
  margin-top: 0;
  margin-bottom: 10px;
  font-family: "Source Sans Pro", sans-serif;
}

/* Firefox */
#tabclix___div_root input[type=number] {
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
#tabclix___div_root button,
#tabclix___div_root html input[type='button'],
#tabclix___div_root input[type='reset'] {
  border: 0;
  cursor: pointer;
  -webkit-appearance: button;
  font-family: "Source Sans Pro", sans-serif;
}
#tabclix___div_root .tabclix-w-form {
  margin: 0 0 15px;
}

#tabclix___div_root label {
  display: block;
  margin-bottom: 5px;
  /* font-weight: bold; */
}
.w-input,
.w-select {
     background-color: #fff;
    border: 1px solid #ccc;
    color: #333;
    display: block;
    font-size: 14px;
    height: 38px;
    line-height: 1.428571429;
    margin-bottom: 10px;
    padding: 8px 12px;
    vertical-align: middle;
    width: 100%;
}
.w-input:-moz-placeholder,
.w-select:-moz-placeholder {
  color: #999;
}
.w-input::-moz-placeholder,
.w-select::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.w-input:-ms-input-placeholder,
.w-select:-ms-input-placeholder {
  color: #999;
}
.w-input::-webkit-input-placeholder,
.w-select::-webkit-input-placeholder {
  color: #999;
}
.w-input:focus,
.w-select:focus {
  outline: 0;
}
.w-input[disabled],
.w-select[disabled],
.w-input[readonly],
.w-select[readonly],
fieldset[disabled] .w-input,
fieldset[disabled] .w-select {
  cursor: not-allowed;
}
textarea.w-input,
textarea.w-select {
  height: auto;
}
.w-select[multiple] {
  height: auto;
}
#tabclix___div_root .w-form-label {
  display: inline-block;
  cursor: pointer;
  font-weight: normal;
  margin-bottom: 0px;
}

#tabclix___div_root [data-nav-menu-open] {
  display: block !important;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #C8C8C8;
  text-align: center;
  overflow: visible;
  min-width: 200px;
}