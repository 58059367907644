
@mixin LargeTablet {
  @media screen and (max-width: 991px) {
      @content;
   }
}

@mixin smallTablet {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@mixin mediumPhone {
  @media screen and (max-width: 479px) {
    @content;
   }
}

@mixin smallPhone {
  @media screen and (max-width: 320px) {
    @content;
   }
}

@mixin medSmllPhone {
  @media screen and (max-width: 376px) {
    @content;
   }
}
