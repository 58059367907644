.main_layout_wrapper {
  overflow: hidden;
  background-color: pink;
}

.main_section_wrapper {
bottom: auto;
    /* height: 100vh; */
    left: 0;
    /* overflow-y: scroll; */
    /* position: fixed; */
    right: 0;
    top: 0;
    z-index: 9000;
    width: 100%;
}
.app_main_layout {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  // background-image: linear-gradient(135deg, #dee2e6, #e9ecef 55%, #f8f9fa);
  background: var(--tabclix-background-color);
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.app_main_layout_wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 100vh;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.wrapper.wf-section {
    top: 0;
    position: fixed;
    bottom: auto;
    left: 0;
    right: 0;
    overflow-y: scroll;
    height: 100vh;
    z-index: 9000;
}